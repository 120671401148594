.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

span[class^="MUIDataTableHeadCell-contentWrapper"]{
  justify-content: center !important;
}

.MuiTable-root {
  border-collapse: separate !important;
}

.alerts-border {
  border: 3px #ff0000 solid;
  font-weight: 300;
  
  animation: blink 1s;
  animation-iteration-count: 60;  
}

@keyframes blink { 
  50% { 
    border-color: transparent;
    font-weight: 600;
  }
}

.time-flash {
  animation: flash 1s;
  animation-iteration-count: 60;
}

@keyframes flash { 
  0% { color: red; }
  50% { color: orange; }
  100% { color: red; } 
}

.red-text{
  color: red;
}
.instructions{
  width:95%;
  border:1px solid black;
   color:red;
   padding:10px;
   margin:15px;
   text-align: center;
}

@media screen and (max-width: 600px) {
  .proiority-button button{
    padding: 20px 13px !important;
  }
  
  .intray-content span{
    word-wrap: break-word;
    max-width: 230px;
  }
}